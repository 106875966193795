import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18M9 6.5a1 1 0 0 1 2 0v4a1 1 0 1 1-2 0zm0 7a1 1 0 1 1 2 0 1 1 0 0 1-2 0",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "currentColor"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M7.409 2.637c1.133-2.183 4.05-2.183 5.182 0l6.028 11.61C19.724 16.375 18.294 19 16.027 19H3.973c-2.267 0-3.697-2.624-2.592-4.754L7.41 2.636ZM9 7a1 1 0 1 1 2 0v4a1 1 0 1 1-2 0zm0 7a1 1 0 1 1 2 0 1 1 0 0 1-2 0",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }